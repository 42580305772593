import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import Login from './screens/login';
import EditCertificate from './screens/editCertificate';
import 'react-toastify/dist/ReactToastify.css';
import SelectApp from './screens/selectApp';
import { getColorScheme } from './services/misc';

const theme = createTheme();

export default function App() {
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [selectApp, setSelectApp] = React.useState(false);
  const [app, setApp] = React.useState('CodingX');

  return (
    <ThemeProvider theme={theme}>
      {!selectApp ? <SelectApp setSelectApp={setSelectApp} app={app} setApp={setApp} /> : !login ? <Login setSelectApp={setSelectApp} app={app} setEmail={setEmail} setButtonDisabled={setButtonDisabled} buttonDisabled={buttonDisabled} setLogin={setLogin} /> : <EditCertificate app={app} email={email} setLogin={setLogin} />}
      <ToastContainer toastStyle={{ backgroundColor: getColorScheme(app).sub, color: "white" }} />
    </ThemeProvider>
  );
}