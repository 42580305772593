export const getGoogleClientId = (app) => {
    if(app==="CodingX"){
        return "667777149387-v39hu20g9ov765trclvtq1mdsafm3jmq.apps.googleusercontent.com";
    }
    if(app==="HackerX"){
        return "16371719826-p9eoh79j8vt8ust0jiqgtmqar92nisj5.apps.googleusercontent.com";
    }
    if(app==="JavaX"){
        return "777336541799-mjo12hjfhc8c031ion4dbcllj689cs5o.apps.googleusercontent.com";
    }
    if(app==="CX"){
        return "724163376606-nhmghkki4cvtt1gbpm31c56if0ast0p4.apps.googleusercontent.com";
    }
    if(app==="CppX"){
        return "169452431039-qtj6u66hjq882o5seuk41bkjs625d42p.apps.googleusercontent.com";
    }
    if(app==="PythonX"){
        return "813102305636-1lllu010ss4b0l98li4crm45rb3sqras.apps.googleusercontent.com";
    }
    return "667777149387-v39hu20g9ov765trclvtq1mdsafm3jmq.apps.googleusercontent.com";
};

export const getColorScheme = (app) => {
    if(app==="CodingX"){
        return {
            main: "#864dfc",
            sub: "#9f70fe"
        }
    }
    if(app==="PythonX"){
        return {
            main: "#4f823b",
            sub: "#79ca41"
        }
    }
    if(app==="HackerX"){
        return {
            main: "#1f2637",
            sub: "#00c95f"
        }
    }
    if(app==="JavaX"){
        return {
            main: "#e9661a",
            sub: "#f9974f"
        }
    }
    if(app==="CX"){
        return {
            main: "#0034f6",
            sub: "#1e84f1"
        }
    }
    if(app==="CppX"){
        return {
            main: "#1f2637",
            sub: "#50b4f4"
        }
    }
    return {
        main: "#864dfc",
        sub: "#9f70fe"
    }
};