import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../components/copyright';
import { FormControl, MenuItem } from '@mui/material';
import { getColorScheme } from '../services/misc';

const theme = createTheme();

export default function SelectApp(props) {
    const { setSelectApp, app, setApp } = props;
    const handleSubmit = (event) => {
        event.preventDefault();
        setSelectApp(true);
    };

    const handleSelectChange = (event) => {
        setApp(event.target.value);
    };
    const colorScheme = getColorScheme(app);
    const mainColor = colorScheme.main;
    const subColor = colorScheme.sub;

    return (
        <ThemeProvider theme={theme} >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 5, bgcolor: mainColor }}>
                        <CheckBoxIcon />
                    </Avatar>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Box sx={{ minWidth: 250 }}>
                            <FormControl fullWidth>
                                <TextField
                                    value={app}
                                    id="app"
                                    name="app"
                                    label="App"
                                    required
                                    onChange={handleSelectChange}
                                    select
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: mainColor
                                            }
                                        },
                                        "& .MuiInputLabel-outlined.Mui-focused": {
                                            color: subColor
                                        },
                                    }}
                                >
                                    <MenuItem value={"CodingX"}>CodingX</MenuItem>
                                    <MenuItem value={"HackerX"}>HackerX</MenuItem>
                                    <MenuItem value={"PythonX"}>PythonX</MenuItem>
                                    <MenuItem value={"CX"}>CX</MenuItem>
                                    <MenuItem value={"CppX"}>CppX</MenuItem>
                                    <MenuItem value={"JavaX"}>JavaX</MenuItem>
                                </TextField>
                            </FormControl>
                        </Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: subColor, '&:hover': {
                                backgroundColor: mainColor
                            } }}
                        >
                            Select App
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{
                    mt: 8, mb: 4,
                    color: mainColor
                }} />
            </Container>
        </ThemeProvider>
    );
}