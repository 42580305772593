import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getUserCertificates, updateCertificateName } from '../services/api';
import { CertificateViewer } from '../components/imageViewer';
import { Copyright } from '../components/copyright';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { toast } from 'react-toastify';
import { getColorScheme } from '../services/misc';

export default function EditCertificate(props) {
    const { email, setLogin, app } = props;
    const colorScheme = getColorScheme(app);
    const mainColor = colorScheme.main;
    const subColor = colorScheme.sub;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);

        const data = new FormData(event.currentTarget);
        const fullName = data.get('fullName');
        const updatePromises = [];
        for (const course of coursesArr) {
            updatePromises.push(updateCertificateName(email, fullName, course, app));
        }
        await Promise.all(updatePromises);
        setCertificates();

        setButtonDisabled(false);
    };

    const setCertificates = async () => {
        const userCertificates = await getUserCertificates(email, app);
        if (userCertificates.images) {
            setImagesArr(userCertificates.images);
            setCoursesArr(userCertificates.courses);
        }
        if (userCertificates.images && !userCertificates.images.length) {
            toast('No certificates were issued for the email ID. Please verify if you have completed your course to unlock certificates.');
        }
    }

    React.useEffect(() => {
        setCertificates();
        // eslint-disable-next-line
    }, []);

    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const [imagesArr, setImagesArr] = React.useState([]);
    const [coursesArr, setCoursesArr] = React.useState([]);

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <IconButton
                variant="contained"
                disabled={buttonDisabled}
                sx={{
                    borderColor: subColor, '&:hover': {
                        borderColor: mainColor,
                        backgroundColor: mainColor,
                        color: 'white'
                    },
                    backgroundColor: subColor, position: 'absolute', right: 20, top: 20, color: 'white'
                }}
                onClick={() => setLogin(false)}
            >
                <LogoutIcon />
            </IconButton>
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{
                    background: `linear-gradient(to right bottom, ${mainColor}, ${subColor})`
                }}
            >
                <CertificateViewer images={imagesArr} />
            </Grid>
            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="fullName"
                            label="Name"
                            name="fullName"
                            autoComplete="fullName"
                            autoFocus
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: mainColor
                                    }
                                },
                                "& .MuiInputLabel-outlined.Mui-focused": {
                                    color: subColor
                                },
                                my: 2
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={buttonDisabled}
                            sx={{
                                mt: 3, mb: 2, backgroundColor: subColor, '&:hover': {
                                    backgroundColor: mainColor
                                }
                            }}
                        >
                            Change Name
                        </Button>
                        <Copyright sx={{
                            mt: 5,
                            color: mainColor
                        }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}